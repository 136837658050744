/* Default styles */
.Embed-container {
    width: 100%;
    height: 84vh;
}

/* Media query for screens smaller than 768px (adjust the breakpoint as needed) */
@media (max-width: 768px) {
    .Embed-container {
        height: 50vh; /* Adjust the height for smaller screens */
    }
}

/* Media query for screens smaller than 576px (adjust the breakpoint as needed) */
/* @media (max-width: 576px) {
    .Embed-container {
        height: 30vh;   */ /* Adjust the height for even smaller screens */
    /*   }
}  */
